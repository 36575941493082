/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-dark bgnone">
        <div className="container-fluid">
          <a className="navbar-brand ms-3 logo" href="/">East Garden Buffet</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="mynavbar">
            <ul className="navbar-nav d-flex ms-auto me-2">
              <li className="nav-item">
                <a className="nav-link navf" href="/Menu">Menu</a>
              </li>
              <li className="nav-item">
                <a className="nav-link navf" href="/Location">Location</a>
              </li>
              <li className="nav-item">
                <a className="nav-link navf" href="/Gallery">Gallery</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar