/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const Map = () => {
  return (
    <div className="text-center px-2 py-2">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11699.666276680417!2d-85.6240306!3d42.8534948!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8819b49f22ceeedf%3A0xc591babd6a697976!2sEast%20Garden%20Buffet!5e0!3m2!1sen!2sin!4v1683785765107!5m2!1sen!2sin"
        className="container"
        height="450"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  );
};

export default Map;
