export const menuList = [
    {
        id: 3,
        title: "menu",
        img: "3.jpg",
        class:"carousel-item",
    },
    {
        id: 4,
        title: "menu",
        img: "4.jpg",
        class:"carousel-item",
    },
    {
        id: 5,
        title: "menu",
        img: "5.jpg",
        class:"carousel-item",
    },
] 