/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import trip from '../../images/trip.png';
import yelp from '../../images/yelp.png';
import fb from '../../images/fb.png';

const Footer = () => {

    const date = new Date().getFullYear();


  return (
    <div className='container-fluid nomar'>
        <div className='row nomar footer py-3'>
            <div className='col-lg-6 footer-text'>
                <h3>Timing</h3>
                <p>
                    Friday-Saturday : 11am-10pm<br/>
                    Sunday-Thursday : 11am-9pm<br/>
                </p>
                <p>
                    <a href='https://www.tripadvisor.com/Restaurant_Review-g42256-d3169837-Reviews-East_Garden_Buffet-Grand_Rapids_Kent_County_Michigan.html?m=19905' target="_blank">
                        <img src={trip} className='trip' alt="" />
                    </a> &nbsp;&nbsp;&nbsp;
                    <a href='https://www.yelp.com/biz/east-garden-buffet-grand-rapids?utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)' target="_blank">
                        <img src={yelp} className='yelp' alt="" />
                    </a>&nbsp;&nbsp;&nbsp;
                    <a href='https://www.facebook.com/people/East-Garden-Buffet/100063899028646/' target="_blank">
                        <img src={fb} className='fb' alt="" />
                    </a>
                </p>
            </div>
            <div className='col-lg-6 footer-text'>
                <h3>East Gargen Buffet</h3>
                <p>
                    6038 Kalamazoo Ave SE,<br/>
                    Grand Rapids,<br/>
                    MI 49508, United States<br/>
                    <a href='tel:+16166988933' className='phone-l'>+1 616-698-8933</a>
                </p>
            </div>
            <div className='col-lg-12 footer-text text-center pt-5'>
                <p className='rights-f'>
                    Designed and Developed by <a className='lagyal' href="http://www.lagyal.com/" target='_blank'>Lagyal.com</a>. <br/>All rights reserved. &copy; {date}-{date+1}.
                </p>
            </div>
        </div>
    </div>
  )
}

export default Footer