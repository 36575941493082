import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import About from './components/About';
import './App.css';
import Menu from './components/Menu';
import Gallery from './components/Gallery';
import Location from './components/Location';
import NotFound from './components/NotFound';

const App = () => {
  return (
    <div>
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path='/Menu' element={<Menu/>}/>
        <Route exact path='/Gallery' element={<Gallery/>}/>
        <Route exact path='/Location' element={<Location/>}/>
        <Route exact path='/About' element={<About/>}/>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
    </div>
  )
}

export default App