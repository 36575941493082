import React from 'react';
import {menuList} from '../menuList';
import Navbar from './Navigation/Navbar';
import "./Styles/Menu.css";
import Footer from './Navigation/Footer';
import menu from '../images/menu/1.jpg';

const Menu = () => {
  return (
    <>
      <div className='container-fluid nomar'>
      <div className="parallaxMenu">      
        <Navbar/>
        <div className='row nomar'>
          <div className='col-lg-12'>
            <h1 className='text-center menu-h'>Savor the Symphony of Flavors!</h1>
            <hr/>
          </div>
          <div className='col-lg-12'>
                      <div className='p-2 text-center'>
                      <img src={menu} className='img-fluid clas' alt='' />
                      </div>
                    </div>
          {
            menuList.map((menu,index)=>{
                return (
                    <div className='col-lg-6 my-3'>
                      <div className='p-2 mx-auto text-center'>
                      <img src={require('../images/menu/'+menu.img)} className='img-fluid clas' alt={menu.title} />
                      </div>
                    </div>
                )
            })
          }
          
        </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Menu