import React from 'react';
import Navigation from './Navigation/Navbar';
import Footer from './Navigation/Footer';
const About = () => {
  return (
    <>
    <Navigation/>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 mx-auto'>
            <h1 className='text-center'>About</h1>
            <p>
            Welcome to The Flavorful Feast! We are a premier buffet restaurant dedicated to serving you an unforgettable dining experience. At The Flavorful Feast, we believe that great food brings people together, and we take immense pride in offering a diverse range of delectable dishes to satisfy every palate.
            <br/><br/>
            Our team of skilled chefs is passionate about culinary excellence and sources only the freshest and highest quality ingredients for our buffet spread. From savory appetizers and vibrant salads to mouthwatering main courses and indulgent desserts, we strive to create a culinary journey that caters to your unique tastes and preferences.
            <br/><br/>
            With our extensive selection, you have the freedom to create your own culinary adventure. Whether you're a meat lover, a seafood enthusiast, a vegetarian, or someone with specific dietary needs, our buffet is designed to accommodate everyone. We ensure a wide variety of options, from international flavors to local favorites, guaranteeing that there's something for everyone.
            <br/><br/>
            Beyond our exceptional cuisine, we provide a warm and inviting ambiance to enhance your dining experience. Our spacious dining area is tastefully decorated, creating a comfortable and relaxed atmosphere for you to enjoy your meal with friends, family, or colleagues. Our friendly and attentive staff is always on hand to ensure your needs are met, ensuring exceptional service from the moment you step through our doors.
            <br/><br/>
            At The Flavorful Feast, we prioritize customer satisfaction, and we continuously strive to exceed your expectations. We are committed to maintaining the highest standards of hygiene and cleanliness, ensuring that your dining experience is both safe and enjoyable.
            <br/><br/>
            Whether you're celebrating a special occasion, enjoying a casual meal, or simply looking to indulge in a feast of flavors, The Flavorful Feast is the perfect destination. Join us and let our buffet restaurant delight your senses with a symphony of tastes, aromas, and textures that will leave you longing for more.
            </p>
          </div>
        </div> 
      </div>
      <Footer/>
    </>
    
  )
}

export default About