import React from 'react';
import Navbar from './Navigation/Navbar';
import foodA from '../images/menu/10.jpg';
import foodB from '../images/menu/11.jpg';
import foodC from '../images/menu/12.jpg';
import Footer from './Navigation/Footer';

const Home = () => {
  return (
    <>
      <div className='container-fluid nomar'>
        <div className="parallax">
            <div className='row text-center nomar'>
              <div className='col-lg-12 nomar'>
                <Navbar/>
              </div>
              <div className='col-lg-12 nomar'>
                <div className='content-px nomar'>
                <h1 className='parallax-h'>
                  ENJOY THE<br/> LARGEST VARIETY OF<br/> CHINESE AND<br/> JAPANESE BUFFET
                </h1>
                <p className='parallax-c pt-2 innerH'>
                  Come and Create your own delicious combo 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='row text-center nomar bgred'>
          <div className='col-lg-8 mx-auto nomar'>
            <div className='content-m nomar'>
            <h3 className='con-h pt-4'>
              ABOUT
            </h3>
            <p className='con-b px-3 pt-4 pb-3'>
              Welcome to The East Garden Buffet
! We are a premier buffet restaurant dedicated to serving you an unforgettable dining experience. At East Garden Buffet, we believe that great food brings people together, and we take immense pride in offering a diverse range of delectable dishes to satisfy every palate.  
            </p>
            </div>
          </div>
        </div>
        <div className="parallaxb">
          <div className='row text-center nomar'>
            <div className='col-lg-12 nomar'>
              <div className='content-px nomar pt-5'>
                <h1 className='parallax-h'>
                Indulge in a Feast of Flavors!"
                </h1>
                <p className='parallax-c pt-2'>
                All-You-Can-Eat Buffet  
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='row text-center nomar bgred'>
          <div className='col-lg-8 my-5 mx-auto nomar'>
            <div className='row nomar'>
              <div className='col-lg-4 py-2'>
                <img src={foodA} className='img-fluid img-rounded' alt='p' />
              </div>
              <div className='col-lg-4 py-2'>
                <img src={foodB} className='img-fluid img-rounded' alt='p' />
              </div>
              <div className='col-lg-4 py-2'>
                <img src={foodC} className='img-fluid img-rounded' alt='p' />
              </div>
            </div>
            <div className='row nomar'>
              <div className='col-lg-12 text-center pt-3'>
                <h2 className='fresh-h'>We serve freshly made food</h2>
                <p className='fresh-b px-2'>
                At East Garden Buffet, we pride ourselves on serving only the freshest food to our valued customers.<br/> 
                Every dish we prepare is made with carefully selected, high-quality ingredients, 
                and our commitment to freshness ensures that your meal is not only delicious but also healthy.
                From appetizers to desserts, we guarantee that you'll be able to taste the difference in every bite.
                Come and experience our commitment to freshly made food today!<br/>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="parallaxc">
          <div className='row text-center nomar'>
            <div className='col-lg-12 nomar'>
              <div className='content-px nomar pt-5'>
                <h1 className='parallax-h'>
                Savor a World of Cuisine
                </h1>
                <p className='parallax-c pt-2'>
                Our Buffet Offers Something for Everyone
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='row text-center nomar bgred'>
          <div className='col-lg-8 mx-auto nomar'>                
              <h3 className='feature-th'>
                  Services
              </h3>
              <hr/> 
              <div className='row nomar py-3'>    
                <div className='col-lg-4 pb-2'>
                  <div className='py-3'>
                  {/* <img src="https://picsum.photos/400" className='img-fluid img-rounded' alt='p' /> */}
                  </div>
                  <h3 className='features-head text-start px-2'>
                    Dine-in
                  </h3>
                  <p className='features-body py-3 text-start px-2'>
                    Experience the joy of dining in at our establishment, where we prioritize your comfort and satisfaction. </p>
                </div>
                <div className='col-lg-4 pb-2'>                 
                  <div className='py-3'>
                  {/* <img src="https://picsum.photos/400" className='img-fluid img-rounded' alt='p' /> */}
                  </div>
                  <h3 className='features-head text-start px-2'>
                    Drive-through
                  </h3>
                  <p className='features-body py-3 text-start px-2'>
                    Experience ultimate convenience with our drive-through feature! All you have to do is to pull up to our designated drive-through area.</p>
                </div>
                <div className='col-lg-4 pb-2'>                
                  <div className='py-3'>
                  {/* <img src="https://picsum.photos/400" className='img-fluid img-rounded' alt='p' /> */}
                  </div>
                  <h3 className='features-head text-start'>
                    No-contact delivery
                  </h3>
                  <p className='features-body py-3 text-start'>
                    At our company, we prioritize your safety and convenience. That's why we offer a seamless and hassle-free no-contact delivery service. </p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Home