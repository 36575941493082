/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import Navbar from './Navigation/Navbar';
import './Styles/Location.css';
import Map from './Sub/Map';
import Footer from './Navigation/Footer';

const Location = () => {

  return (
    <>
      <div className='container-fluid nomar'>
        <div className="parallaxLocation">      
        <Navbar/>
          <div className='row nomar'>
            <div className='col-lg-12 nomar'>
              <h1 className='text-center location-h pt-5 mt-5'>Savor the Flavors, <br/>Come and Indulge in Culinary Delight!</h1>
            </div>
          </div>
        </div>
        <div className='row nomar'>
          <div className='col-lg-8 mx-auto'>
            <h2 className='text-left pt-4 ps-3'>East Gargen Buffet</h2>
            <p className='add ps-3'>
            6038 Kalamazoo Ave SE, <br/>Grand Rapids, <br/>MI 49508, United States
            <br/>
            <a href='tel:+16166988933' className='phone-l'>+1 616-698-8933</a>
            </p>
          </div>          
          <div className='col-lg-8 mx-auto'>
            <Map/>
          </div>
        </div>
      </div>
      <Footer/>
    </>
    
  )
}

export default Location