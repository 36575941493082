import React from 'react';
import "./Styles/Gallery.css";
import firstSlide from "../images/d.jpg";
import sSlide from "../images/e.jpg";
import Navbar from './Navigation/Navbar';
import Footer from './Navigation/Footer';


const Gallery = () => {

  return (
    <>
      <div className='container-fluid nomar'>
        <div className="parallaxGallery">      
        <Navbar/>
          <div className='row nomar'>
            <div className='col-lg-12 nomar'>
              {/* 
                start
               */}
               <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={firstSlide} class="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item">
                    <img src={sSlide}  class="d-block w-100" alt="..."/>
                  </div>
                  <div class="carousel-item">
                    <img src={firstSlide} class="d-block w-100" alt="..."/>
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
               {/* end */}
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
    
  )
}

export default Gallery